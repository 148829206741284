import React from "react";
import styled from "styled-components";
import NotFoundBackground from "./NotFoundBackground";
import theme from "../../../assets/theme";

export default () => {
  return (
    <NotFoundBackground>
      <Content>
        <ContentContainer>
          <LeftColumn>
            <Title>OooPS!</Title>
            <Subtitle>
              We can't find the page
              <br />
              that you're looking for :(
            </Subtitle>
            <SecondSubtitle>Try to <StyledLink href="/">go back</StyledLink> and try again</SecondSubtitle>
          </LeftColumn>
        </ContentContainer>
        <BoxContainer>
          <Box src={require("../../../assets/images/box_404.svg")} />
        </BoxContainer>
      </Content>
    </NotFoundBackground>
  );
};

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  height: 100%;
`;

const RightColumn = styled(Column)`
  align-items: flex-end;
`;

const ContentContainer = styled(RightColumn)`
  @media (max-width: 900px) {
    align-items: center;
  }
`;

const LeftColumn = styled(Column)`
  align-items: flex-start;
`;

const BoxContainer = styled(LeftColumn)`
  @media (max-width: 900px) {
    display: none;
  }
`;

const Box = styled.img`
  max-width: 100%;
  max-height: 50%;
  display: block;
`;

const Title = styled.h1`
  font-size: 3.75rem;
  margin: 0;
  margin-bottom: 2.5rem;
  font-family: "Comfortaa-Bold";
  color: ${theme.dark};
`;

const Subtitle = styled.h2`
  font-size: 1.25rem;
  margin: 0;
  font-family: "Comfortaa-Light";
  color: ${theme.dark};
`;

const SecondSubtitle = styled(Subtitle)`
  margin-top: 1.5rem;
`;

const StyledLink = styled.a`
  font-family: "Comfortaa-Medium";
  color: ${theme.dark};
  font-size: 1.25rem;
  text-decoration: none;
`;
