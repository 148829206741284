import React from "react";
import styled from "styled-components";

import Fonts from "../assets/fonts/Fonts";
import Router from "./routes/Router";

const App = () => (
  <AppContainer>
    <Fonts />
    <Router />
  </AppContainer>
);

export default App;

const AppContainer = styled.div`
  width: 100%;
  height: 100%;
`;
