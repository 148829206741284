import React from "react";
import styled from "styled-components";

const Control = styled.button`
  height: 5rem;
  flex: 1;
  border: none;
  outline: none;
  background: no-repeat center;
  min-width: 10rem;
  cursor: pointer;
`;

export default Control;
