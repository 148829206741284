import React, { useEffect, useState } from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";

export default () => {
  const [isNeedShow, setShow] = useState(false);

  useEffect(() => {
    const isAccepted = localStorage.getItem("cookie_accepted");
    if (!isAccepted) {
      setShow(true);
    }
  });

  const acceptCookies = () => {
    localStorage.setItem("cookie_accepted", true);
    setShow(false);
  };

  return (
    <CookieContainer isNeedShow={isNeedShow}>
      <ContentContainer>
        <Title>This Website Uses Cookies</Title>
        <Subtitle>By using our website you consent to all cookies in accordance with our cookie policy</Subtitle>
      </ContentContainer>
      <AcceptButton onClick={acceptCookies}>Got it!</AcceptButton>
    </CookieContainer>
  );
};

const CookieContainer = styled.div`
  position: absolute;
  bottom: 0;

  width: 100%;
  padding: 2rem;
  background: ${theme.bgLight};
  transform: translateY(${(props) => (props.isNeedShow ? "0%" : "100%")});
  transition: transform 0.3s ease-out 0.5s;
  display: flex;
  align-items: center;
  box-shadow: 0px -3px 15px 0px rgba(0,0,0,0.15);
`;

const AcceptButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background: ${theme.dark};
  color: white;
  font-size: 1.3rem;
  font-family: "Comfortaa-Medium";
  padding: 1rem 1.5rem;
  flex-shrink: 0;
`;

const ContentContainer = styled.div`
  width: calc(100% - 10.5rem);
`;

const Title = styled.h3`
  color: ${theme.dark};
  margin: 0;
  font-family: "Comfortaa-Bold";
  font-size: 1.6rem;
`;

const Subtitle = styled.h4`
  color: ${theme.dark};
  margin: 0;
  margin-top: 1rem;
  font-size: 1.3rem;
  font-family: "Comfortaa-Light";
  font-weight: 400;
`;
