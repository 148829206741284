import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
@font-face{
    font-family: 'Comfortaa-Medium';
    src:         url(${require("./Comfortaa-Medium.ttf")});      
}
@font-face{
    font-family: 'Comfortaa-Bold';
    src: url(${require("./Comfortaa-Bold.ttf")});
}
@font-face{
    font-family: 'Comfortaa-Regular';
    src:         url(${require("./Comfortaa-Regular.ttf")});      
}
@font-face{
    font-family: 'Comfortaa-Light';
    src:         url(${require("./Comfortaa-Light.ttf")});      
}
@font-face{
    font-family: 'FredokaOne-Regular';
    src: url(${require("./FredokaOne-Regular.ttf")});
}
`;
