import React from "react";
import styled from "styled-components";

export default ({ children }) => {
  return (
    <BackgroundContainer>
      <ContentWrapper>{children}</ContentWrapper>
    </BackgroundContainer>
  );
};

const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background: url(${require("../../../assets/images/bg_404.svg")}) no-repeat center;
  background-size: cover;

  @media (max-width: 900px) {
    background-position-x: 27%;
  }
`;

const ContentWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;
