import React from "react";
import styled from "styled-components";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "../../../assets/override-reactjs-popup.css";

import theme from "../../../assets/theme";

export default ({ open, close }) => {
  return (
    <Popup open={open} closeOnDocumentClick onClose={close}>
      <ModalContainer>
        <CloseButton onClick={close}>
          <CloseIcon src={require("../../../assets/images/close.svg")} />
        </CloseButton>
        <Title>Coming soon</Title>
        <Text>
          The app for the AppStore is
          <br /> under construction
        </Text>
      </ModalContainer>
    </Popup>
  );
};

const ModalContainer = styled.div`
  background: url("${require("../../../assets/images/popup bg.svg")}") no-repeat center;
  background-size: cover;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 9rem 3rem;
  position: relative;
`;

const Title = styled.h2`
  margin: 0;
  color: ${theme.dark};
  font-family: "Comfortaa-Light";
  font-size: 2.5rem;
  text-transform: uppercase;
  white-space: nowrap;
`;

const Text = styled.div`
  color: ${theme.dark};
  font-family: "Comfortaa-Light";
  font-size: 1.5rem;
  margin-top: 1.5rem;
  text-align: center;
`;

const CloseButton = styled.button`
  border-radius: 50%;
  border: none;
  outline: none;
  position: absolute;
  right: -0.75rem;
  top: -0.75rem;
  font-size: 1.5rem;
  padding: 0.5rem;
  color: ${theme.dark};
  background: #bbceea;
  cursor: pointer;
`;

const CloseIcon = styled.img`
  width: 1rem;
  height: 1rem;
`;
