import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Landing from "../pages/landing/Landing";
import Privacy from "../pages/static/Privacy";
import Terms from "../pages/static/Terms";
import NotFound from "../pages/notFound/NotFound";

export default () => (
  <Router>
    <Switch>
      <Route path="/" exact>
        <Landing />
      </Route>
      <Route path="/privacy" exact>
        <Privacy />
      </Route>
      <Route path="/terms" exact>
        <Terms />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  </Router>
);
