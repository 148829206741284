import React, { useState } from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";
import CookieMessage from "./CookieMessage";
import Footer from "./Footer";
import Control from "./Control";
import ComingSoonPopup from "./ComingSoonPopup";

import LandingBackground from "./LandingBackground";

const IOS = "ios";
const ANDROID = "android";

function isDevEnv() {
  return window.location.origin.indexOf("desie") < 0;
}

export default () => {
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);

  const onAppMarketClicked = (market) => {
    if (!isDevEnv())
      window.gtag("event", "click", {
        event_category: "market",
        event_label: market,
      });

    if (market === IOS) {
      setOpen(true);
    }
  };

  return (
    <LandingBackground>
      <Content>
        <ContentContainer>
          <LeftColumn>
            <Title>Desie app</Title>
            <SubtitleLight>The place where</SubtitleLight>
            <Subtitle>your desires live</Subtitle>
            <ControlContainer>
              <AppleButton onClick={onAppMarketClicked.bind(this, IOS)} />
              <ComingSoonPopup open={open} close={closeModal} />
              <GoogleButton onClick={onAppMarketClicked.bind(this, ANDROID)} />
            </ControlContainer>
          </LeftColumn>
        </ContentContainer>
        <PhoneContainer>
          <Phone src={require("../../../assets/images/phone.png")} />
        </PhoneContainer>
      </Content>
      <Footer />
      <CookieMessage />
    </LandingBackground>
  );
};

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  height: 100%;
`;

const RightColumn = styled(Column)`
  align-items: flex-end;
`;

const ContentContainer = styled(RightColumn)`
  @media (max-width: 900px) {
    align-items: center;
  }
`;

const LeftColumn = styled(Column)`
  align-items: flex-start;
`;

const PhoneContainer = styled(LeftColumn)`
  @media (max-width: 900px) {
    display: none;
  }
`;

const Phone = styled.img`
  max-width: 100%;
  max-height: 50%;
  margin-left: 10%;
  display: block;
`;

const Title = styled.h1`
  font-size: 3.75rem;
  margin: 0;
  margin-bottom: 3rem;
  font-family: "FredokaOne-Regular";
  color: ${theme.dark};
`;

const Subtitle = styled.h2`
  font-size: 1.25rem;
  margin: 0;
  font-family: "Comfortaa-Bold";
  color: ${theme.dark};
  text-transform: uppercase;
`;

const SubtitleLight = styled(Subtitle)`
  color: ${theme.light};
  margin-bottom: 0.5rem;
`;

const ControlContainer = styled.div`
  width: 100%;
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
`;

const GoogleButton = styled(Control)`
  background-image: url(${require("../../../assets/images/googlePlay.png")});
`;

const AppleButton = styled(Control)`
  background-image: url(${require("../../../assets/images/appStore.png")});
  margin-right: 1rem;
`;
