import React from "react";
import styled from "styled-components";

export default ({ children }) => {
  return (
    <Wrapper>
      <LeftCurtains src={require("../../../assets/images/left curtain.png")} />
      <RightCurtains src={require("../../../assets/images/right curtain.png")} />
      <Flowers src={require("../../../assets/images/flowers.svg")} />
      <Gifts src={require("../../../assets/images/gifts.png")} />
      <ContentWrapper>{children}</ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background: url(${require("../../../assets/images/bg.svg")}) no-repeat center;
  background-size: cover;
`;

const LeftCurtains = styled.img`
  max-width: 30%;
  position: absolute;
  top: 0;
  left: 0;
  @media (max-width: 900px) {
    max-width: 35%
  }
`;

const RightCurtains = styled.img`
  max-width: 32%;
  position: absolute;
  top: 0;
  right: 0;
  @media (max-width: 900px) {
    max-width: 40%
  }
`;

const Flowers = styled.img`
  position: absolute;
  bottom: 0;
  left: 2%;
  max-width: 10%;
  @media (max-width: 900px) {
    max-width: 20%
  }
`;

const Gifts = styled.img`
  position: absolute;
  bottom: 3rem;
  right: 3%;
  max-width: 15%;
  @media (max-width: 900px) {
    max-width: 25%
  }
`;

const ContentWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;
