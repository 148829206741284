import React from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";

export default () => {
  return (
    <Footer>
      <StyledLink href={"/privacy"}>Privacy Policy</StyledLink>
      <StyledLink href={"/terms"}>Terms of Use</StyledLink>
      <StyledLink href={"mailto:routemeteam@gmail.com"}>Contact us</StyledLink>
      Desie (c) 2020
    </Footer>
  );
};

const Footer = styled.footer`
  font-family: "Comfortaa-Regular";
  color: ${theme.dark};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  bottom: 1rem;
  right: 1rem;

  @media (max-width: 900px) {
    flex-wrap: wrap;
    width: 80%;
    bottom: 0.5rem;
  }
`;

const StyledLink = styled.a`
  cursor: pointer;
  margin-right: 1rem;
  font-size: 1rem;
  color: ${theme.dark};
  font-family: "Comfortaa-Regular";
  text-decoration: none;
  flex-shrink: 0;

  @media (max-width: 900px) {
    &:nth-child(2) {
      margin-right: 0;
    }
  }
`;
